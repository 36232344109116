import React, { useState } from "react";
// import Logo from "../../../common/Logo";
import useLogout from "../../../common/useLogout";
import { Link } from "react-router-dom";
import "components/common/Cancelation/cancelation.css";
import "components/client/dashboard/Reps/repCard/repCard.css";
import { ICONS } from "components/common/constants/swal.constants";
import GenericModal from "components/common/GenericModal";

const NotConfirmedMessage = () => {
  const [genericModalData, setGenericModalData] = useState({
    show: false,
  });
  const showLogoutSuccess = () => {
    setGenericModalData({
      show: true,
      title: "Goodbye",
      subtext: "See you next time!",
      icon: ICONS.checkMark,
    });
  };
  const showLogoutError = () => {
    setGenericModalData({
      show: true,
      title: "Oops!",
      subtext: "Some error occurred",
      icon: ICONS.error,
    });
  };
  const hideModal = () => {
    setGenericModalData((prevState) => ({ ...prevState, show: false }));
  };

  const logOut = useLogout(
    "/login",
    showLogoutSuccess,
    showLogoutError,
    hideModal
  );
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
        className="OnboardingForm"
      >
        {genericModalData.show && (
          <GenericModal
            show={genericModalData.show}
            onHide={hideModal}
            title={genericModalData?.title || ""}
            subtext={genericModalData?.subtext || ""}
            icon={genericModalData?.icon || undefined}
            hideBtn
          />
        )}
        <div
          style={{
            width: "100%",
            maxWidth: 500,
            margin: "84px 0",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            flex: 1,
            padding: "1em",
            boxShadow: "1px 3px 18px 0px #0000001A",
            borderRadius: 8,
          }}
        >
          {/* <div>
            <Logo style={{ width: 200 }} />
          </div> */}

          <div
            className="cm-title-container"
            style={{ position: "relative", alignSelf: "unset" }}
          >
            <img
              src={ICONS.info}
              alt=""
              style={{ height: 34, width: 34, objectFit: "contain" }}
            />
            <p className="modal-title" style={{ display: "flex", flex: 1 }}>
              Welcome
            </p>

            <div
              onClick={logOut}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "5%",
                bottom: 0,
              }}
            >
              <span style={{ marginLeft: 8, fontSize: "1.5rem", opacity: 0.5 }}>
                ✕
              </span>
            </div>
          </div>
          <p className="modal-subtitle">{` You've registered successfully! You’ll get an email (usually within
            a business day) after we've had a chance to review your profile. If
            we have any questions about any information you provided we will
            reach out to you.`}</p>

          <Link to="/">
            <button className="generic-success-btn" style={{ width: "100%" }}>
              Go Back Home
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotConfirmedMessage;
