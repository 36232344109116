import React from "react";
import { Modal } from "react-bootstrap";
import "components/common/Cancelation/cancelation.css";
import "components/client/dashboard/Reps/repCard/repCard.css";
import { ICONS } from "./constants/swal.constants";

interface GenericModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  subtext?: string;
  htmlSubtext?: string;
  hideBtn?: boolean;
  btnText?: string;
  proceedBtnText?: string;
  onBtnClick?: () => void;
  onProceedBtnClick?: () => void;
  icon?: string;
}
const GenericModal: React.FC<GenericModalProps> = ({
  show,
  onHide,
  title,
  subtext,
  btnText,
  onBtnClick,
  htmlSubtext,
  icon,
  hideBtn,
  proceedBtnText,
  onProceedBtnClick,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <div className="modal-container">
        <div className="cm-title-container">
          <img
            src={icon || ICONS.roundCheck}
            alt=""
            style={{ height: 54, width: 54, objectFit: "contain" }}
          />
          <p className="modal-title">{title}</p>
        </div>
        {htmlSubtext ? (
          <div
            className="modal-subtitle"
            dangerouslySetInnerHTML={{ __html: htmlSubtext }}
          ></div>
        ) : (
          <p className="modal-subtitle">{subtext}</p>
        )}
        {hideBtn === true ? null : (
          <div className="un-follow-buttons-container">
            <button className="generic-success-btn" onClick={onBtnClick}>
              {btnText || "OK"}
            </button>
            {proceedBtnText && (
              <button
                className="generic-proceed-btn"
                onClick={onProceedBtnClick}
              >
                {proceedBtnText || "OK"}
              </button>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default GenericModal;
