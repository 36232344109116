import React, { useMemo, useState } from "react";
import "./common.css";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { SectionOptions } from "components/rep/accounts/cardNewDesign/types";
import { FaInfoCircle } from "react-icons/fa";
import CustomTooltip from "components/common/CustomTooltip";
interface LoginInputProps {
  label: string;
  placeholder?: string;
  value?: string | number;
  name: string;
  onChange: (prop: string) => (value: string) => void;
  type: string;
  helperText?: string | null;
  onEnter?: () => Promise<void>;
  customInputStyle?: LooseObject;
  customInputFieldStyle?: LooseObject;
  customContainerStyle?: LooseObject;
  options?: SectionOptions[];
  disabled?: boolean;
  prefix?: string;
  infoToolTip?: string;
  onFocus?: () => void;
}
const LoginInput: React.FC<LoginInputProps> = ({
  label,
  placeholder,
  value,
  name,
  onChange,
  type,
  helperText,
  onEnter,
  customInputStyle,
  customContainerStyle,
  customInputFieldStyle,
  options,
  disabled,
  prefix,
  infoToolTip,
  onFocus,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = () => setShowPassword((prev) => !prev);
  const inputType = useMemo(() => {
    if (type !== "password") return type;
    if (showPassword) return "text";
    return type;
  }, [type, showPassword]);
  const passwordIcon = useMemo(() => {
    if (type !== "password") return null;
    if (showPassword)
      return (
        <IoEyeOutline className="show-password-icon" onClick={togglePassword} />
      );
    return (
      <IoEyeOffOutline
        className="show-password-icon"
        onClick={togglePassword}
      />
    );
  }, [type, showPassword]);

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnter) {
      e.preventDefault();
      await onEnter();
    }
  };

  const renderInput = useMemo(() => {
    switch (type) {
      case "select":
        return (
          <select
            className="login-input"
            name={name || "select"}
            value={value || ""}
            onChange={(e) => onChange(name)(e.target.value)}
            style={customInputFieldStyle || {}}
            disabled={disabled}
          >
            <option value="" disabled>
              {placeholder || "Select an option"}
            </option>
            {options?.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case "area":
        return (
          <textarea
            className="login-input"
            placeholder={placeholder || "Enter text"}
            value={value || ""}
            name={name || "textarea"}
            rows={5}
            onChange={(e) => onChange(name)(e.target.value)}
            style={customInputFieldStyle || {}}
            disabled={disabled}
          />
        );
      default:
        return (
          <input
            type={inputType}
            className="login-input"
            placeholder={placeholder || "Enter"}
            value={value || ""}
            name={name || "input"}
            onChange={(e) => onChange(name)(e.target.value)}
            onKeyDown={handleKeyDown}
            style={customInputFieldStyle || {}}
            disabled={disabled}
            onFocus={() => {
              if (onFocus) onFocus();
            }}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, name, value, placeholder, options, disabled, inputType]);
  return (
    <div className="login-input-container" style={customContainerStyle || {}}>
      <div className="label-tooltip-container">
        <p className="login-input-label">{label}</p>
        {infoToolTip && (
          <CustomTooltip
            tooltipClass="login-info-tooltip"
            tooltip={infoToolTip}
            customContainerStyle={{
              position: "relative",
              display: "flex",
            }}
          >
            <FaInfoCircle style={{ cursor: "pointer", color: "#5184cf" }} />
          </CustomTooltip>
        )}
      </div>
      <div className="input-items-container" style={customInputStyle || {}}>
        {prefix && <span className="input-items-prefix">{prefix}</span>}
        {renderInput}
        {passwordIcon}
      </div>
      {helperText && <p className="login-from-helper-text">{helperText}</p>}
    </div>
  );
};

export default LoginInput;
