export const SWAL_ICON = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const ICONS = {
  checkMark:
    "https://img.icons8.com/?size=100&id=sz8cPVwzLrMP&format=png&color=000000",
  roundCheck:
    "https://img.icons8.com/?size=100&id=70yRC8npwT3d&format=png&color=000000",
  error: "https://img.icons8.com/?size=100&id=3062&format=png&color=f06f73",
  foodCheck:
    "https://img.icons8.com/?size=100&id=9fp9k4lPT8us&format=png&color=000000",
  info: "https://img.icons8.com/?size=100&id=GLnivQZXkbdA&format=png&color=3f7aca",
  warning:
    "https://img.icons8.com/?size=100&id=67F23l3ehnE5&format=png&color=000000",
};
