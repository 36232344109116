import React, { useEffect } from "react";
import * as EmailService from "../../services/emailService";
//import loaderImg from "../../assets/images/newloader.gif";
import "components/common/Cancelation/cancelation.css";
import "components/client/dashboard/Reps/repCard/repCard.css";
import { ICONS } from "components/common/constants/swal.constants";
import { useHistory } from "react-router-dom";

const useQueryParams = () => {
  const params = new URLSearchParams(window ? window.location.search : {});

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop);
    },
  });
};

const Confirm = (props) => {
  const guid = props.match?.params?.token;
  const history = useHistory();
  const onConfirmSuccess = () => {
    props.history.push("/login");
  };

  const onConfirmFailure = (err) => {
    props.history.push("/error-404");
  };

  useEffect(() => {
    if (guid)
      EmailService.confirmAcct(...[guid])
        .then((res) => {
          if (res.success) {
            setTimeout(onConfirmSuccess, 5000);
          }
        })
        .catch(onConfirmFailure);
  }, [guid]);

  if (!guid) {
    return <div>No token found</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        className="modal-container"
        style={{
          padding: 36,
          borderRadius: 32,
          boxShadow: "1px 3px 18px 0px #0000001A",
        }}
      >
        <div className="cm-title-container">
          <img
            src={ICONS.info}
            alt=""
            style={{ height: 54, width: 54, objectFit: "contain" }}
          />
          <p className="modal-title">Email Confirmed!</p>
        </div>

        <p className="modal-subtitle">
          Thank you for signing up with MyRep.
          <br />
          In a few seconds you will be redirected to the login page.
        </p>

        <div className="un-follow-buttons-container">
          <button
            className="generic-success-btn"
            onClick={() => history.push("/login")}
          >
            Click here to go there now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
