import React from "react";
import Logo from "./Logo.js";
import MainFooter from "../../components/layout/MainFooter";
import "components/common/Cancelation/cancelation.css";
import "components/client/dashboard/Reps/repCard/repCard.css";
import { ICONS } from "components/common/constants/swal.constants";
const CheckYourEmail = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        paddingTop: "2em",
      }}
    >
      <Logo style={{ maxWidth: 100, marginBottom: 24, alignSelf: "center" }} />

      <div
        className="modal-container"
        style={{
          padding: 36,
          borderRadius: 32,
          boxShadow: "1px 3px 18px 0px #0000001A",
          alignSelf: "center",
        }}
      >
        <div className="cm-title-container">
          <img
            src={ICONS.info}
            alt=""
            style={{ height: 54, width: 54, objectFit: "contain" }}
          />
          <p className="modal-title">Please check your email</p>
        </div>

        <p className="modal-subtitle">
          We've sent an email to the email address you just registered with.
        </p>
      </div>
      <MainFooter />
    </div>
  );
};

export default CheckYourEmail;
