/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, FC, createContext, useReducer } from "react";
type Action = {
  type:
    | "SET_OFFICES"
    | "SET_OFFICES_PREFERENCES"
    | "SET_OFFICES_DIET_DATA"
    | "SET_OFFICES_I_FOLLOW"
    | "SET_BOOKED_APPOINTMENTS"
    | "FETCH_INITIATE"
    | "FETCH_COMPLETE"
    | "SET_APPROVALS"
    | "REFETCH_BOOKED_APPOINTMENTS"
    | "SET_OFFICE_APPOINTMENT_REQUIREMENTS"
    | "SET_OFFICE_NOTIFICATION_PREFERENCES"
    | "REFETCH_SWITCH_REQUESTS"
    | "SET_SENT_SWITCH_REQUESTS"
    | "SET_INCOMING_SWITCH_REQUESTS"
    | "SET_AVAILABLE_PRACTICE_TYPES";
  payload: LooseObject;
};
export interface SwitchRequest {
  requestId?: number;
  createdAt?: string;
  initiatorDate?: string;
  initiatorTime?: number;
  initiatorType?: string;
  receiverDate?: string;
  receiverTime?: number;
  receiverType?: string;
  officeName?: string;
  otherUserFirstName?: string;
  otherUserLastName?: string;
}
interface Data {
  offices: LooseObject[];
  officesPreferences: LooseObject[];
  officesDietData: LooseObject[];
  officesIFollow: LooseObject[];
  bookedAppointments: LooseObject[];
  refetchBookedAppointments: boolean;
  isLoading: boolean;
  approvals: LooseObject[];
  officeNotificationPreferences: number[] | null;
  officeAppointmentRequirements: LooseObject;
  refetchSwitchRequests: boolean;
  sentAppointmentSwitchRequests: SwitchRequest[];
  incomingAppointmentSwitchRequests: SwitchRequest[];
  availablePracticeTypes: Array<JObj>;
}

interface ContextType {
  data: Data;
  dispatchAction: Dispatch<Action>;
}

const initialData: Data = {
  offices: [],
  officesPreferences: [],
  officesDietData: [],
  officesIFollow: [],
  bookedAppointments: [],
  refetchBookedAppointments: false,
  isLoading: false,
  approvals: [],
  officeNotificationPreferences: [],
  officeAppointmentRequirements: {},
  refetchSwitchRequests: false,
  sentAppointmentSwitchRequests: [],
  incomingAppointmentSwitchRequests: [],
  availablePracticeTypes: [],
};

export const RepDataContext = createContext<ContextType | undefined>(undefined);

const reducer = (data: Data, action: Action) => {
  switch (action.type) {
    case "SET_OFFICES":
      return { ...data, offices: action.payload.offices };
    case "SET_OFFICES_PREFERENCES":
      return { ...data, officesPreferences: action.payload.officesPreferences };
    case "SET_OFFICES_DIET_DATA":
      return { ...data, officesDietData: action.payload.officesDietData };
    case "SET_OFFICES_I_FOLLOW":
      return { ...data, officesIFollow: action.payload.officesIFollow };
    case "SET_OFFICE_APPOINTMENT_REQUIREMENTS":
      return {
        ...data,
        officeAppointmentRequirements:
          action.payload.officeAppointmentRequirements,
      };
    case "SET_OFFICE_NOTIFICATION_PREFERENCES":
      return {
        ...data,
        officeNotificationPreferences:
          action.payload.officeNotificationPreferences,
      };
    case "SET_BOOKED_APPOINTMENTS":
      return {
        ...data,
        bookedAppointments: action.payload.bookedAppointments,
        refetchBookedAppointments: false,
      };
    case "SET_APPROVALS": {
      return { ...data, approvals: action.payload.approvals };
    }
    case "REFETCH_BOOKED_APPOINTMENTS":
      return { ...data, refetchBookedAppointments: true };
    case "REFETCH_SWITCH_REQUESTS":
      return { ...data, refetchSwitchRequests: true };
    case "SET_SENT_SWITCH_REQUESTS":
      return {
        ...data,
        sentAppointmentSwitchRequests:
          action.payload.sentAppointmentSwitchRequests,
      };
    case "SET_INCOMING_SWITCH_REQUESTS":
      return {
        ...data,
        incomingAppointmentSwitchRequests:
          action.payload.incomingAppointmentSwitchRequests,
      };
    case "SET_AVAILABLE_PRACTICE_TYPES":
      return {
        ...data,
        availablePracticeTypes: action.payload.availablePracticeTypes,
      };
    default:
      return data;
  }
};

export const RepDataContextProvider: FC<{ children: any }> = ({ children }) => {
  const [data, dispatchAction] = useReducer(reducer, initialData);
  return (
    <RepDataContext.Provider value={{ data, dispatchAction }}>
      {children}
    </RepDataContext.Provider>
  );
};
