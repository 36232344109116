import React, { forwardRef, useState, MouseEvent } from "react";
import "components/homeRedesign/css/request-demo.css";
import "components/client/dashboard/profile/Cards/cards.css";
import { isValidEmail } from "components/common/utilities";
import LoginInput from "components/auth/common/LoginInput";
import { generateTimeOptions } from "components/auth/common/constants";
import { makeToast } from "helpers/common";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineEditCalendar } from "react-icons/md";
import { sendDemoRequest } from "services/emailService";

interface RequestDemoFormProps {}

interface iRequestFormData {
  officeName?: string;
  requesterName?: string;
  contactNumber?: string;
  meetEmail?: string;
  date?: string;
  time?: string;
  sendCopy?: boolean;
  copyEmail?: string;
}
interface iValidationHelpers {
  officeName?: string | null;
  requesterName?: string | null;
  contactNumber?: string | null;
  meetEmail?: string | null;
  copyEmail?: string | null;
}
type EndDatePickerInputProps = {
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};
const requiredFields = [
  "officeName",
  "requesterName",
  "contactNumber",
  "meetEmail",
  "time",
  "date",
];
const RequestDemoForm: React.FC<RequestDemoFormProps> = () => {
  const [formData, setFormData] = useState<iRequestFormData>({});
  const [validationHelpers, setValidationHelpers] =
    useState<iValidationHelpers>({});
  const [loading, setLoading] = useState<boolean>(false);
  const handleChange = (prop: string) => (value: string | boolean) => {
    let helperText: string | null = null;

    switch (prop) {
      case "meetEmail":
        setValidationHelpers((prev: iValidationHelpers) => ({
          ...prev,
          [prop]: !isValidEmail(value)
            ? "Please enter a valid email address."
            : null,
        }));
        break;
      case "copyEmail":
        setValidationHelpers((prev: iValidationHelpers) => ({
          ...prev,
          [prop]: !isValidEmail(value)
            ? "Please enter a valid email address."
            : null,
        }));
        break;
      default:
        if (
          !formData?.[prop as keyof iRequestFormData] ||
          formData?.[prop as keyof iRequestFormData] === ""
        )
          helperText = "This field is required";
        setValidationHelpers((prev: iValidationHelpers) => ({
          ...prev,
          [prop]: helperText,
        }));
        break;
    }
    setFormData((prev: iRequestFormData) => ({
      ...prev,
      [prop]: value,
    }));
  };
  const handleFocus = (prop: keyof iRequestFormData) => {
    let helperText: string | null = null;
    if (!formData?.[prop] || formData?.[prop] === "")
      helperText = "This field is required";
    setValidationHelpers((prev: iValidationHelpers) => ({
      ...prev,
      [prop]: helperText,
    }));
  };
  const checkValidation = () => {
    let isValid: boolean | string = false;

    requiredFields.forEach((key) => {
      if (
        formData?.[key as keyof iRequestFormData] === null ||
        !formData?.[key as keyof iRequestFormData]
      ) {
        isValid = `${key} is required`;
      }
    });
    if (!isValidEmail(formData.meetEmail))
      isValid = "Please enter a valid meet email address";
    if (formData.sendCopy && !isValidEmail(formData.copyEmail))
      isValid = "Please enter a valid copy email address";
    console.log({ isValid });

    return isValid;
  };
  const handleSubmit = async () => {
    console.log({ formData, validationHelpers });
    const validationError = checkValidation();
    if (validationError) {
      makeToast(`${validationError}`, "error");
      return;
    } else {
      console.log("proceeding....");
      try {
        setLoading(true);

        const response = await sendDemoRequest(formData);
        console.log({ response });
        if (response.success) makeToast(response.message, "success");
        else makeToast(response.message, "error");

        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
        makeToast("Something went wrong, please try again latter", "error");
      }
    }
  };

  const EventDatePickerInput = forwardRef<
    HTMLDivElement,
    EndDatePickerInputProps
  >(({ onClick }, ref) => (
    <div ref={ref} className="rd-date-input-container">
      <p className="login-input-label">Date*</p>

      <div className="rd-date-input" onClick={onClick}>
        <span style={{ display: "flex", flex: 1 }}>
          {formData.date
            ? moment(formData.date).format("YYYY-MM-DD")
            : "Which Date would be most convenient to schedule a google meet?"}
        </span>
        <MdOutlineEditCalendar size={20} />
      </div>
    </div>
  ));
  EventDatePickerInput.displayName = "EventDatePickerInput";
  return (
    <div className="rd-page-container">
      <p className="rd-title">MyRep Demo Request</p>
      <p className="rd-subtitle">
        *The information you provide is strictly for the use of MyRep and will
        not be
        <br /> shared with any third party or anyone outside the MyRep
        organization.
      </p>

      <div className="rd-from-container">
        <LoginInput
          label={"Office Name*"}
          value={formData?.officeName}
          name={"officeName"}
          onChange={handleChange}
          type="text"
          placeholder={"Name of the office you work for"}
          customInputFieldStyle={{ color: "#3F77CD" }}
          onFocus={() => handleFocus("officeName")}
          helperText={validationHelpers.officeName}
        />
        <LoginInput
          label={"Your Name*"}
          value={formData?.requesterName}
          name={"requesterName"}
          onChange={handleChange}
          type="text"
          placeholder={"Who may we ask to speak to?"}
          customInputFieldStyle={{ color: "#3F77CD" }}
          onFocus={() => handleFocus("requesterName")}
          helperText={validationHelpers.requesterName}
        />
        <LoginInput
          label={"Contact Number*"}
          value={formData?.contactNumber}
          name={"contactNumber"}
          onChange={handleChange}
          type="text"
          placeholder={"Please provide a phone number"}
          customInputFieldStyle={{ color: "#3F77CD" }}
          onFocus={() => handleFocus("contactNumber")}
          helperText={validationHelpers.contactNumber}
        />
        <LoginInput
          label={"Email Address*"}
          value={formData?.meetEmail}
          name={"meetEmail"}
          onChange={handleChange}
          type="email"
          placeholder={"Email address for google meet demo"}
          customInputFieldStyle={{ color: "#3F77CD" }}
          helperText={validationHelpers.meetEmail}
        />
        <ReactDatePicker
          selected={formData.date ? new Date(`${formData.date}`) : new Date()}
          minDate={new Date(moment().add(1, "day").format())}
          onChange={(e: Date | null) => {
            const value: string = moment(e).format("YYYY-MM-DD");
            handleChange("date")(value);
          }}
          customInput={<EventDatePickerInput />}
        />
        <LoginInput
          label={"Time*"}
          value={formData?.time}
          name={"time"}
          onChange={handleChange}
          type="select"
          placeholder={"Best Time to Reach You"}
          customInputFieldStyle={{ color: "#3F77CD" }}
          options={generateTimeOptions()}
        />
        <label className={"od-checkbox-container"}>
          <input
            className={"od-checkbox"}
            type="checkbox"
            name="sendCopy"
            style={{ marginRight: 12 }}
            checked={Boolean(formData.sendCopy)}
            onChange={(e) => handleChange("sendCopy")(e.target.checked)}
          />
          Send me a copy of my responses
        </label>
        {formData?.sendCopy && (
          <LoginInput
            label={"Email Address*"}
            value={formData?.copyEmail}
            name={"copyEmail"}
            onChange={handleChange}
            type="email"
            placeholder={"Email address to send copy to"}
            customInputFieldStyle={{ color: "#3F77CD" }}
            helperText={validationHelpers.copyEmail}
          />
        )}
        <button
          className="login-form-btn"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default RequestDemoForm;
